import $ from 'jquery';

window.Downloads = window.Downloads || {}
window.Downloads.New = function () {
  $('#download_email').on('change', function () {
    var val = $(this).val();

    if (val.match(/\./) && !val.match(/\.(gov|edu)$/)) {
      $('.usage-notes').show()
    } else {
      $('.usage-notes').hide().find('input').val('')
    }
  })
}

window.Downloads.New.submit = function (token) {
  $('#new_download').submit()
}

// recaptcha requires a function in the global namespace
window.submitNewDownloadForm = Downloads.New.submit
